import React from 'react'
import LeftNavigation from './left.navigation'
import { useSelector } from 'react-redux'
import { Flex, Tag, theme as ANTDTheme, Typography } from 'antd'
import { Icons } from '../../../components/atoms/general/icons/Icons'
import Utils from '../../../utils'

function PlayNavigation () {
  const { game, question } = useSelector(state => state.Game)
  const { useToken } = ANTDTheme
  const { token: theme } = useToken()

  return !game?.loading && (game?.status !== null && game?.status !== 'queue') && (
    <header className={['cr-w-full cr-px-6'].join(' ')}
            style={{
              background: Utils.shadeColor(theme.colorPrimary, -55),
            }}>
      <div className={'cr-flex cr-items-center cr-justify-between cr-gap-4 cr-h-full'}>
        <Flex align={'center'} gap={20}>
          <Typography.Title level={1} className={'!cr-text-white !cr-m-0'}>INTERCOMMEDIA</Typography.Title>
          {question && (<Tag
            size={'small'}
            style={{
              borderColor: question?.type?.color,
              color: question?.type?.color ? Utils.shadeColor(question?.type?.color, 100) : 'white',
              background: question?.type?.color,
            }}
            icon={<Icons type={question?.type?.icon} />}
            shape={'round'}
          >{question?.type?.name}</Tag>)}

        </Flex>
        <div />
        <LeftNavigation />
      </div>
    </header>)
}

export { PlayNavigation }