import React, { useContext } from 'react'
import { Button, Flex, Popconfirm, theme as ANTDTheme, Tooltip } from 'antd'
import { Icons } from '../../../components/atoms/general/icons/Icons'
import { FullScreenContext } from '../../../context/FullScreenContext'
import { connect, useDispatch } from 'react-redux'
import { AudioPlayerBtn } from './audio/audio-player.btn'
import { FormContext } from './context/form-context'
import { Handlers } from './handlers'
import { QuestionDuration } from './question-duration'
import { GAME_STATUS } from '../../../constants/game.constant'
import { GameSettingOpen, GameSubmitQuestion } from '../../../redux/actions'

function LeftNavigation ({ question, questions, game }) {
  const dispatch = useDispatch()
  const { useToken, getDesignToken, defaultSeed } = ANTDTheme
  const { token: theme } = useToken()
  const { enter, exit, state: fullscreen } = useContext(FullScreenContext)
  const { form } = useContext(FormContext)

  function _onSubmit () {
    form.validateFields({ validateOnly: true })
      .then((values) => {
        console.log({ values })
        dispatch(GameSubmitQuestion({
          dto: {
            ...values,
            duration: game?.duration,
          },
        }))
        form.resetFields()
      })

      .catch(Handlers.error)
  }

  return (
    <React.Fragment>
      <Flex justify={'end'} align={'center'} gap={40} className={'cr-min-w-[180px]'}>

        <Flex gap={10} align={'center'} className={'cr-h-full'}>
          {
            (game?.status !== GAME_STATUS.COMPLETED && game?.status !== GAME_STATUS.DONE) && (
              <QuestionDuration current={question?.duration ?? 10} />
            )
          }

          <Button
            icon={<Icons type={'TrophyOutlined'} />}
            type={'primary'}
            ghost
            style={{
              borderColor: theme.gold7,
              color: theme.gold7,
              background: theme.gold2,
            }}
            shape={'round'}>
            <span>{game?.current ? (game?.current + 1) : 1}/{(questions?.data && Array.isArray(questions?.data) ? questions?.data.length : 0)}</span>
          </Button>
          <Button
            icon={<Icons type={'DashboardOutlined'} />}
            type={'primary'}
            ghost
            style={{
              borderColor: theme.gold5,
              color: theme.gold5,
              background: '#262626',
            }}
            shape={'round'}>
            <span>{[game?.scores, Handlers.calcTotalScore(questions?.data)].join('/')}</span>
          </Button>

        </Flex>

        <Flex gap={10} justify={'end'} align={'center'}>
          {
            (game?.status !== GAME_STATUS.COMPLETED && game?.status !== GAME_STATUS.DONE) && (
              <Popconfirm
                title={'Are you sure?'}
                onConfirm={_onSubmit}
              >
                <Button
                  type="primary">
                  Submit
                </Button>
              </Popconfirm>
            )

          }
          <AudioPlayerBtn />
          <Tooltip title={'Mode Fullscreen'}>
            <Button
              onClick={fullscreen?.isActive === true ? exit : enter}
              style={{
                minWidth: '40px',
              }}
              type={'primary'}
              icon={<Icons type={fullscreen?.isActive === true ? 'FullscreenExitOutlined' : 'ExpandOutlined'} />}
            />
          </Tooltip>
          <Tooltip title={'Settings'}>
            <Button
              onClick={() => dispatch(GameSettingOpen(true))}
              style={{
                minWidth: '40px',
              }}
              type={'primary'}
              shape={'circle'}
              icon={<Icons type={'SettingOutlined'} />}
            />
          </Tooltip>
        </Flex>

      </Flex>

    </React.Fragment>
  )
}

export default connect(({ Game }) => {
  let { question, questions, game } = Game
  return { question, questions, game }
}, {})(React.memo(LeftNavigation))