import { EVENT, FAILURE, REQUEST, SUCCESS } from '../actions/action.type'
import {
  GAME_AUDIO_CHANGE,
  GAME_CHANGE_STATUS_AUDIO, GAME_CHANGE_VOLUME,
  GAME_CHECK_POINT,
  GAME_CLOSE_BUMPER,
  GAME_FORCE_NEXT_OR_FINISH, GAME_FORCE_SUBMIT,
  GAME_INTERVAL_TIME, GAME_NEXT_PROGRESS, GAME_SEND_RESULT, GAME_SETTING_OPEN,
  GAME_START, GAME_SUBMIT_QUESTION, GAME_TIME_UP,
  GET_GAME,
  GET_QUESTION_GAME,
} from '../constants/Game'
import { REHYDRATE } from 'redux-persist/es/constants'
import { first } from 'lodash'
import { GAME_BUMPER_TYPE, GAME_STATUS } from '../../constants/game.constant'
import { Handlers } from '../../views/play-views/components/handlers'

let _game = {
  uuid: null,
  running: false,
  current: null, // current question step
  status: null, // null | "progress" | "completed" | "done" | "queue" | "force-next"
  options: {
    show_bumper: false,
    type_bumper: GAME_BUMPER_TYPE.OPENING,
  },
  scores: 0,
  correct: 0,
  incorrect: 0,
  total_duration: 0,
  duration: 0,
  _sendingAPI: {
    isSuccess: false,
    isFailure: false,
    loading: false,
  },
}
let _answer = {
  quizUuid: null,
  data: [],
}
const initialState = {
  setting: {
    isFullscreen: false,
    open: false,
    audio: {
      url: '/music/instrument-game.mp3',
      options: {
        volume: .4,
        status: 'pause',
        autoPlay: true,
      },
    },
  },
  themes: {
    current: null,
    collections: {
      loading: false,
      data: [],
    },
  },
  gameType: null,
  question: null,
  game: _game,
  quiz: {
    uuid: null,
    data: null,
    loading: false,
  },
  answer: _answer,
  questions: {
    loading: true,
    data: [],
    pagination: {
      page: 1,
      limit: 10,
      max_page: 0,
      total_record: 0,
    },
  },
}

export default function (state = initialState, { type, payload, key }) {
  switch (type) {
    case REHYDRATE:
      if (key === 'game') {
        state = { ...state, ...payload }
        console.log({ state })
        Reflect.set(state.themes, 'current', payload?.game?.data?.theme ?? null)
      }
      if (state.game.status === GAME_STATUS.COMPLETED) {
        state.setting.audio.url = state?.quiz?.data?.theme?.musicCredit ?? '/music/short-music.mp3'
      }
      return {
        ...state,
        setting: {
          ...state.setting,
          audio: {
            ...state.setting.audio,
          },
        },
        game: {
          ...state.game,
          status: state?.game?.status ?? null,
          current: state?.game?.current ?? null,
        },
      }

    case EVENT('GAME_CLEAR'):
      return {
        ...state,
        gameType: null,
        answer: {
          ...state.answer,
          ...initialState.answer,
        },
        game: {
          ...state.game,
          ...initialState.game,
        },
      }
    case REQUEST(GET_GAME):
      return {
        ...state,
        quiz: {
          ...state.quiz,
          loading: state?.quiz?.uuid === payload?.uuid,
          uuid: payload?.uuid,
          loading: true,
        },
      }
    case SUCCESS(GET_GAME):
      if (payload?.uuid !== state?.game?.uuid) {
        state.game = {
          ..._game,
        }
        state.answer = {
          ..._answer,
        }
      }
      if (state.game.status === GAME_STATUS.COMPLETED) {
        state.setting.audio.url = payload?.theme?.musicCredit ?? '/music/short-music.mp3'
      }
      return {
        ...state,
        setting: {
          ...state.setting,
        },
        game: {
          ...state.game,
          uuid: payload?.uuid,
        },
        quiz: {
          ...state.quiz,
          loading: false,
          data: payload,
        },
      }
    case FAILURE(GET_GAME):
      return {
        ...state,
        quiz: {
          ...state.quiz,
          loading: false,
          data: null,
        },
      }

    case REQUEST(GET_QUESTION_GAME):
      return state
    case SUCCESS(GET_QUESTION_GAME):
      if ((state.game.current !== null && state.game?.current >= 0)) {
        if (Array.isArray(payload?.data) && payload?.data.length > 0 && typeof (payload?.data[state.game.current]) !== 'undefined') {
          let item = payload?.data[state.game.current]
          state.question = item
          state.gameType = item?.type?.slug
        }
      } else {
        let item = first(payload?.data)
        state.question = item
        state.gameType = item?.type?.slug
      }
      return {
        ...state,
        gameType: state?.gameType,
        question: state?.question,
        questions: {
          ...state.questions,
          loading: false,
          data: [...payload?.data],
          pagination: {
            ...state.questions.pagination,
            ...payload?.pagination,
          },
        },
      }

    case EVENT(GAME_START):
      if (Array.isArray(state.quiz.data)) {
        console.log(state.quiz.data, 'DATAAA')
        state.gameType = first(state.questions.data)?.type?.slug
      }
      return {
        ...state,
        gameType: state?.gameType,
        game: {
          ...state.game,
          current: null, // current question step
          status: GAME_STATUS.QUEUE, // null | "progress" | "completed" | "done" | "queue"
          options: {
            ...state.game.options,
            show_bumper: true,
            type_bumper: GAME_BUMPER_TYPE.OPENING,
          },
        },
      }
    case EVENT(GAME_CLOSE_BUMPER):
      if (Array.isArray(state.questions.data)) {
        console.log(state.quiz.data, 'DATAAA')
        state.gameType = first(state.questions.data)?.type?.slug
      }
      return {
        ...state,
        setting: {
          ...state.setting,
          audio: {
            ...state.setting.audio,
            url: '/music/instrument-game.mp3',
          },
        },
        game: {
          ...state.game,
          running: true,
          status: GAME_STATUS.PROGRESS,
          current: 0,
          options: {
            ...state.game.options,
            show_bumper: false,
          },
        },
      }

    case EVENT(GAME_INTERVAL_TIME):
      return {
        ...state,
        game: {
          ...state.game,
          duration: payload?.second ?? 0,
        },
      }
    case EVENT(GAME_FORCE_NEXT_OR_FINISH):
      state.game.duration = state.game.duration + 1
      return {
        ...state,
        game: {
          ...state.game,
          running: false,
          status: GAME_STATUS.FORCE,
          total_duration: state.game.total_duration + state.game.duration,
        },
      }
    case EVENT(GAME_TIME_UP):
      return {
        ...state,
        game: {
          ...state.game,
          running: false,
          status: GAME_STATUS.TIME_UP,
        },
      }

    case EVENT(GAME_CHECK_POINT):
      let game_force_submit_current_question = state.game.current + 1
      state.game.duration = 0
      if (typeof (payload?.dto) !== 'undefined' && Object.keys(payload?.dto).length > 0) {
        let _newAnswer = [
          ...state.answer.data,
          {
            ...payload?.dto,
          },
        ]

        const _calculate = Handlers.calculateCorrectAnswer(_newAnswer)
        state.game.correct = _calculate.filter((child) => child?.correct === true).length
        state.game.incorrect = _calculate.filter((child) => child?.correct === false).length
        state.game.scores = _calculate.map((child) => child?.points).reduce((a, b) => a + b)
        state.answer.data = _newAnswer
      }
      if ((game_force_submit_current_question) < (state.questions?.data?.length)) {
        if (typeof (state.questions?.data[game_force_submit_current_question]) !== 'undefined') {
          let question = state.questions.data[game_force_submit_current_question]
          state.question = question
          state.gameType = question.type?.slug
        }
        state.game.current = game_force_submit_current_question
        state.game.status = GAME_STATUS.CHECK_POINT
      } else {
        state.game.status = GAME_STATUS.COMPLETED
        state.game.current = game_force_submit_current_question - 1
        state.setting.audio.url = state?.quiz?.data?.theme?.musicCredit ?? '/music/short-music.mp3'
      }
      return {
        ...state,
        game: {
          ...state.game,
          running: false,
          current: state.game.current,
          status: state.game.status,
        },
      }

    case EVENT(GAME_NEXT_PROGRESS):
      if (state.game.status === GAME_STATUS.COMPLETED || state.game.status === GAME_STATUS.DONE) {
        state.game.status = GAME_STATUS.COMPLETED
        state.game.running = false
      } else {
        console.log(state.game.status, state.game.current, 'STATUS')
        if (state.game.current) {
          if (state.game.current === state.questions.data.length) {
            state.game.status = GAME_STATUS.COMPLETED
            state.game.running = false
          } else {
            state.game.running = true
            state.game.status = GAME_STATUS.PROGRESS
          }
        } else {
          state.game.running = false
          state.game.status = GAME_STATUS.QUEUE
        }

      }

      return {
        ...state,
        game: {
          ...state.game,
          running: state.game.running,
          status: state.game.status,
        },
      }
    case EVENT(GAME_SUBMIT_QUESTION):
      return {
        ...state,
        answer: {
          ...state.answer,
          data: state?.answer.data,
        },
        game: {
          ...state.game,
          status: GAME_STATUS.SUBMIT,
          running: false,
          total_duration: state.game.total_duration + state.game.duration,
        },
      }
    case EVENT(GAME_FORCE_SUBMIT):
      return {
        ...state,
        answer: {
          ...state.answer,
          data: state?.answer.data,
        },
        game: {
          ...state.game,
          status: GAME_STATUS.FORCE_SUBMIT,
        },
      }

    case EVENT(GAME_CHANGE_STATUS_AUDIO):
      return {
        ...state,
        setting: {
          ...state.setting,
          audio: {
            ...state.setting.audio,
            options: {
              ...state.setting.audio.options,
              ...payload?.options,
            },
            url: payload?.url ?? state.setting.audio.url,
          },
        },
      }

    case REQUEST(GAME_SEND_RESULT):
      return {
        ...state,
        game: {
          ...state.game,
          running: false,
          _sendingAPI: {
            ...state.game._sendingAPI,
            loading: true,
          },
        },
      }
    case SUCCESS(GAME_SEND_RESULT):
      return {
        ...state,
        game: {
          ...state.game,
          status: GAME_STATUS.COMPLETED,
          running: false,
          _sendingAPI: {
            ...state.game._sendingAPI,
            isSuccess: true,
            isFailure: false,
            loading: false,
          },
        },
      }
    case FAILURE(GAME_SEND_RESULT):
      return {
        ...state,
        game: {
          ...state.game,
          running: false,
          _sendingAPI: {
            ...state.game._sendingAPI,
            isSuccess: false,
            isFailure: true,
            loading: false,
          },
        },
      }

    case EVENT(GAME_AUDIO_CHANGE):
      return {
        ...state,
        setting: {
          ...state.setting,
          audio: {
            ...state.setting.audio,
            options: {
              ...state.setting.audio.options,
              ...payload?.options,
            },
            url: payload?.url ?? state.setting.audio.url,
          },
        },
      }

    case EVENT(GAME_CHANGE_VOLUME):
      return {
        ...state,
        setting: {
          ...state.setting,
          audio: {
            ...state.setting.audio,
            options: {
              ...state.setting.audio.options,
              volume: payload,
            },
          },
        },
      }
    case EVENT(GAME_SETTING_OPEN):
      return {
        ...state,
        setting: {
          ...state.setting,
          open: payload,
        },
      }
    default:
      return state
  }
}