import React from 'react'
import { Menu, Dropdown, Avatar, Typography, Flex } from 'antd'
import { connect } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import Icon from 'components/util-components/Icon'
import { signOut } from 'redux/actions/Auth'
import styled from 'styled-components'

const menuItem = [
  {
    title: 'Account Setting',
    icon: SettingOutlined,
    path: '/settings/account',
  },
]

const MenuProfile = styled(Menu)`
    background: transparent !important;
    box-shadow: 0 0 0 !important;
`

const NavProfile = ({ signOut, user }) => {
  console.log({ user })
  const profileImg = user?.data?.avatar ?? '/img/avatars/thumb-1.jpg'
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <Typography.Title level={5}
                              className="mb-0 !cr-text-md !cr-tracking-tighter">{user?.data?.fullName}</Typography.Title>
            <span className="text-muted">{user?.data?.email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <MenuProfile className="!cr-bg-transparent" rootClassName={'!cr-bg-transparent'}>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i} icon={<Icon type={el.icon} />}>
                <a href={el.path}>

                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            )
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()} icon={<LogoutOutlined />}>
            <span className="font-weight-normal">Sign Out</span>
          </Menu.Item>
        </MenuProfile>
      </div>
    </div>
  )
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile"
                   className={'!cr-flex !cr-items-center !cr-justify-center'}>
          <Avatar size={40} src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

export default connect(({ auth }) => {
  let { user } = auth
  return {
    user,
  }
}, { signOut })(NavProfile)
