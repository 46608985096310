import React, { useState, useEffect, useContext } from 'react'
import { WindowFocusContext } from '../../../../context/window-focus'
import { useSelector } from 'react-redux'
import { GAME_STATUS } from '../../../../constants/game.constant'

export const TimeContext = React.createContext({})

const TimeContextProvider = ({ children, ...props }) => {
  const { state } = useContext(WindowFocusContext)
  const { status, current, running } = useSelector(({ Game }) => Game.game)
  const [currentTime, setCurrentTime] = useState(new Date())
  const [seconds, setSeconds] = useState(() => {
    if (
      typeof (props?.initialValues) !== 'undefined' &&
      typeof (props?.initialValues.second) !== 'undefined'
    ) return props?.initialValues.second
    return 0
  })

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)
    return () => clearInterval(timerID)
  }, [state, running, current])

  const tick = () => {
    const now = new Date()
    setCurrentTime(now)
    if (running && status === GAME_STATUS.PROGRESS) {
      setSeconds((prev) => prev + 1)
    } else {
      setSeconds(0)
    }
  }
  return (
    <TimeContext.Provider value={{
      current: currentTime,
      seconds: seconds,
    }}>
      {children}
    </TimeContext.Provider>
  )
}

export default TimeContextProvider