export const GET_GAME = 'GAME/QUIZ'
export const GET_QUESTION_GAME = 'GAME/QUESTION'

export const GAME_AUDIO_CHANGE = 'GAME_AUDIO_CHANGE'
export const GAME_START = 'GAME/START'
export const GAME_CLOSE_BUMPER = 'GAME/CLOSE_BUMPER'

export const GAME_INTERVAL_TIME = 'GAME/INTERVAL_TIME'

export const GAME_TIME_UP = 'GAME/TIME-UP'
export const GAME_CHECK_POINT = 'GAME/CHECK-POINT'
export const GAME_NEXT_PROGRESS = 'GAME/NEXT_PROGRESS'
export const GAME_FORCE_NEXT_OR_FINISH = 'GAME/FORCE-NEXT'
export const GAME_FORCE_SUBMIT = 'GAME/FORCE-SUBMIT'

export const GAME_SEND_RESULT = 'GAME/SEND_RESULT'

export const GAME_SUBMIT_QUESTION = 'GAME/SUBMIT_QUESTION'

export const GAME_CHANGE_VOLUME = 'GAME/CHANGE_VOLUME'
export const GAME_CHANGE_STATUS_AUDIO = 'GAME/CHANGE_STATUS_AUDIO'

export const GAME_SETTING_OPEN = 'GAME/SETTING_OPEN'
export const GAME_SETTING_VOLUME = 'GAME/SETTING_VOLUME'